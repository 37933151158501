.qr-code-generating-button {
  box-shadow: none !important;
  outline: none !important;
  span {
    font-size: 12px !important;
  }
}
.qr-code-generating-button:hover {
  text-decoration: underline !important;
  color: rgb(69, 69, 214) !important;
}

.qr-code-modal {
  .modal-content {
    border-radius: 10px !important;
    .qr-code-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .content-1 {
        font-size: 20px;
        color: black;
        margin-bottom: 3px;
      }
    }
    .modal-footer {
      .btn {
        color: black !important;
      }
      .btn:hover {
        text-decoration: underline !important;
        color: rgb(69, 69, 214) !important;
      }
    }
  }
}

.qr-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.qr-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.qr-container {
  text-align: center;
  margin-top: 20px;
}

.qr-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-loading {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 4px;
  width: 260px;
  height: 260px;
  background-color: white;
  padding: 10px;
}

.qr-block {
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.1);
  animation: qrFadeIn 1.5s infinite alternate ease-in-out;
}

.qr-block:nth-child(odd) {
  animation-delay: 0.3s;
}

.qr-loading-text {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
  color: #555;
}

@keyframes qrFadeIn {
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.mfa-settings {
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 256px;
  }
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
